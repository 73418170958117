<template>
  <div class="user-admin">
    <v-card>
      <v-toolbar flat>
        <v-card-title>Administrar usuários do sistema</v-card-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-container fluid>
        <v-data-table
          :loading="loading"
          :items-per-page="resultsPerPage"
          :items="users"
          :headers="fields"
          hide-default-footer
          hide-details
          disable-pagination
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                placeholder="Pesquisar por nome do usuário"
                v-model="filters.search"
                append-icon="mdi-magnify"
                clearable
                @click:append="loadUsers"
                @click:clear="clearFilters"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                rounded
                small
                @click="redirectToAddUserPAge"
              >
                <v-icon> mdi-plus </v-icon> Cadastrar
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              class="ml-1"
              small
              icon
              title="Editar"
              @click="redirectToUpdatePage(item.id)"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>

            <v-btn
              small
              icon
              class="ml-1"
              :title="item.itsBlocked ? 'Desbloquear' : 'Bloquear'"
              @click="changeLock(item)"
            >
              <v-icon>
                {{ item.itsBlocked ? "mdi-account-check" : "mdi-account-off" }}
              </v-icon>
            </v-btn>
            <v-btn
              class="ml-1"
              small
              icon
              title="Excluir"
              @click="deleteUser(item.id)"
            >
              <v-icon> mdi-trash-can </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
    <v-pagination
      v-model="filters.currentPage"
      :length="numberOfPages"
      :total-visible="resultsPerPage"
      @change="loadUsers"
      class="d-flex justify-content-end mt-2"
    >
      <template v-slot:page></template>
    </v-pagination>
  </div>
</template>

<script>
import moment from "moment";
import errorHandler from "@/helpers/error_handler";

export default {
  name: "Users",
  components: {},
  data: function() {
    return {
      filters: {
        AccessProfileId: null,
        search: null,
        currentPage: 1,
      },
      totalRows: 0,
      resultsPerPage: 15,
      loading: false,
      mode: "insert",
      user: {},
      users: [],
      accessProfileOptions: [],
      fields: [
        { value: "name", text: "Nome", sortable: true },
        { value: "AccessProfile.name", text: "Perfil de acesso" },
        { value: "createdAt", text: "Criado em" },
        { value: "actions", text: "Ações" },
      ],
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  methods: {
    afterLoadUsers(response) {
      this.totalRows = response.count;

      this.users = response.rows.map((user) => {
        user.name = user.Person.fullName;
        user.createdAt = moment(user.createdAt).format("DD/MM/YYYY - HH:mm");

        return user;
      });
    },
    async loadUsers(page) {
      try {
        let currentPage = page ? page : 1;

        const url = `/users`;
        this.loading = true;

        const res = await this.$axios.get(url, {
          params: {
            employeeFunction: this.filters.employeeFunction,
            search: this.filters.search,
            page: currentPage,
          },
        });

        this.afterLoadUsers(res.data);

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    redirectToAddUserPAge() {
      this.$router.push("/usuarios/cadastrar");
    },
    redirectToUpdatePage(userId) {
      this.$router.push("/usuarios/editar/" + userId);
    },
    showModal(mode) {
      if (mode === "insert") {
        this.$refs["InsertEmployee"].show();
      } else {
        this.$refs["EditEmployee"].show();
      }
    },
    hideModal(event) {
      if (event.mode === "insert") {
        this.users.push(event.data);
        this.$refs["InsertEmployee"].hide();
      } else {
        let users = [...this.users];

        const employeeIndex = users.findIndex((e) => e.id === event.data.id);

        users[employeeIndex] = event.data;

        this.users = users;

        this.$refs["EditEmployee"].hide();
      }
    },
    async confirmModal(title, msg, options) {
      return await this.$root.$confirm(title, msg, options);
    },
    async deleteUser(userId) {
      try {
        const confirm = await this.confirmModal(
          "Atenção",
          "Deseja mesmo deletar o usuário selecionado?",
          {
            color: "red",
          }
        );

        if (confirm) {
          this.loading = true;

          await this.$axios.delete(`/users/${userId}`);

          this.deleteOfList(userId);
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    deleteOfList(userId) {
      let users = [...this.users];

      let userIndex = users.findIndex((e) => e.id === userId);

      users.splice(userIndex, 1);

      this.users = users;
    },
    async changeLock(user) {
      try {
        const confirm = await this.confirmModal(
          "Atenção",
          !user.itsBlocked
            ? "Deseja mesmo bloquear o usuário selecionado?"
            : "Deseja mesmo desbloquear o usuário selecionado?",
          {
            color: "red",
          }
        );
        if (confirm) {
          this.loading = true;

          await this.$axios.put(`/users/${user.id}/changeblock`, {
            itsBlocked: !user.itsBlocked,
          });

          this.loading = false;
          this.loadUsers();
        }
      } catch (error) {
        this.loading = false;
        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async redirectToUpdateAccessProfilesPage() {
      this.$router.push("/perfis");
    },
    clearFilters() {
      this.filters = {
        AccessProfileId: null,
        search: null,
        currentPage: 1,
      };

      this.loadUsers();
    },
  },
  mounted() {
    this.loadUsers();
  },
};
</script>

<style></style>
